
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageAnalytics from '../../../atomic/organisms/Page-Analytics/Page-Analyitics.vue';
import Table from '../../../atomic/organisms/Table/Table.vue';
import NavTabs from '../../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import PageControls from '../../../atomic/organisms/Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../utilities/Types/table.types';
import { Tabs } from '../../../utilities/Types/navigation.types';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import axios from 'axios';
import router from '@/router';

export default defineComponent({
  components: {
    'arc-page-analytics': PageAnalytics,
    'arc-table': Table,
    'arc-nav-tabs': NavTabs,
    'arc-page-controls': PageControls,
  },
  async mounted(): Promise<void> {
    const response = await this.fetchEmailConnections({
      page: this.pageValue.value,
      limit: this.showValue.value,
      search: this.searchFilter,
      sortKey: this.sortDefinition.column,
      sortDirection: this.sortDefinition.direction,
    });
    this.total = response?.total;

    if (this.$route.query.emailId) {
      this.updateModalComponent('email');
      this.updateModalType('large');
      this.updateModalData({ emailId: this.$route.query.emailId, mode: 'edit' });
      this.openModal();
    }
  },
  watch: {
    resetPagination: {
      deep: true,
      async handler() {
        this.showValue = { description: '25', value: 25 };
        this.pageValue = { description: '1', value: 1 };
        const response = await this.fetchEmailConnections({
          page: this.pageValue.value,
          limit: this.showValue.value,
          search: this.searchFilter,
          sortKey: this.sortDefinition.column,
          sortDirection: this.sortDefinition.direction,
        });
        this.total = response?.total;
      },
    },
    loadingEmails: {
      handler() {
        this.loading = this.loadingEmails;
      },
    },
  },
  computed: {
    ...mapGetters(['emails', 'loadingEmails', 'user', 'access', 'permissions', 'resetPagination']),
    isLoading(): boolean {
      return this.loading || this.loadingEmails;
    },
    showSearch(): boolean {
      return this.permissions && this.permissions?.superAdminAccess;
    },
    columnDefinitions(): ColumnDefinition[] {
      if (this.permissions && this.permissions?.superAdminAccess) {
        return [
          {
            type: 'text',
            columnName: 'id',
            displayName: 'ID',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'alias',
            displayName: 'Alias',
            sortable: true,
          },
          {
            type: 'text',
            columnName: 'primaryUserEmail',
            displayName: 'Primary User',
          },
          {
            type: 'stringArray',
            columnName: 'secondaryUsersEmails',
            displayName: 'Secondary Users',
          },
          {
            type: 'text',
            columnName: 'statusDescription',
            displayName: 'Status',
          },
          {
            type: 'text',
            columnName: 'emailId',
            displayName: 'Email ID',
          },
        ];
      }
      return [
        {
          type: 'text',
          columnName: 'id',
          displayName: 'ID',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'alias',
          displayName: 'Alias',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'primaryUserEmail',
          displayName: 'Primary User',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'statusDescription',
          displayName: 'Status',
        },
        {
          type: 'stringArray',
          columnName: 'secondaryUsersEmails',
          displayName: 'Secondary Users',
        },
      ];
    },
    tableData(): unknown {
      return this.emails;
    },
    tabs(): Tabs {
      return [
        // {
        //   title: 'OPPORTUNITIES',
        //   route: 'view',
        //   activeRouteCheck: '/opportunities/view',
        // },
        {
          title: 'EMAILS',
          route: 'emails',
          activeRouteCheck: '/opportunities/emails',
        },
      ];
    },
    tableOptions(): any {
      return {
        clickable: true,
      };
    },
    pageOptions(): IDropdownOption[] {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(total / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      return Math.ceil(total / showValue);
    },
  },

  data(): {
    statusFilterValue: IDropdownOption;
    statusFilterOptions: IDropdownOption[];
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    allTableData: any;
    sortDefinition: SortDefinition;
    loading: boolean;
    total: number;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
  } {
    return {
      statusFilterValue: { description: 'All', value: 'all' },
      statusFilterOptions: [
        { description: 'All', value: 'all' },
        { description: 'Active', value: 'active' },
        { description: 'Upcoming', value: 'upcoming' },
        { description: 'Expired', value: 'expired' },
        { description: 'Draft', value: 'draft' },
      ],
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      allTableData: [],
      sortDefinition: { column: 'id', type: 'text', direction: 'DESC' },
      loading: true,
      total: 0,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
    };
  },

  methods: {
    ...mapActions(['openModal', 'updateModalType', 'updateModalComponent', 'updateModalData', 'fetchEmailConnections']),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    updateStatusFilter(filter: IDropdownOption) {
      this.resetPageValue();
      this.statusFilterValue = filter;
    },
    async sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
      const response = await this.fetchEmailConnections({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    async updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
      const response = await this.fetchEmailConnections({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    async updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
      const response = await this.fetchEmailConnections({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    async previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
      const response = await this.fetchEmailConnections({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    async nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
      const response = await this.fetchEmailConnections({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    async search(searchFilter: string): Promise<void> {
      this.resetPageValue();
      this.searchFilter = searchFilter;
      const response = await this.fetchEmailConnections({
        page: this.pageValue.value,
        limit: this.showValue.value,
        search: this.searchFilter,
        sortKey: this.sortDefinition.column,
        sortDirection: this.sortDefinition.direction,
      });
      this.total = response?.total;
    },
    create(): void {
      this.updateModalComponent('email');
      this.updateModalType('large');
      this.openModal();
    },
    rowClicked(row: any): void {
      this.updateModalComponent('email');
      this.updateModalType('large');
      this.updateModalData({ emailId: row?.emailId, mode: 'edit' });
      this.openModal();
      router.replace({ query: { emailId: row?.emailId } });
    },
  },
});
